import React, { useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 1rem;
  box-sizing: border-box;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  width: 100%;
  max-width: 800px; /* Width for the text container */
  padding: 1rem;
`;

const TextContainer = styled.div`
  width: 100%;
  text-align: center;
  // margin-bottom: 0.2rem; /* Space between text and form */
`;

const Title = styled.h2`
  font-size: 2rem;
  // margin-bottom: 0.5rem;
`;

const Subtitle = styled.p`
  font-size: 1rem;
  margin-bottom: 1rem;
  color: #666;
`;

const Form = styled.form`
  background: white;
  padding: 2rem;
  border-radius: 10px;
  width: 100%;
  max-width: 600px; /* Fixed size for the form */
  display: flex;
  flex-direction: column;
  gap: 1rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Add some shadow for better visualization */
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  font-size: 1rem;
  margin-bottom: 0.5rem;
  color: #333;
`;

const Input = styled.input`
  padding: 0.35rem;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  outline: none;
  &:focus {
    border-color: #007bff;
  }
`;

const Textarea = styled.textarea`
  padding: 0.35rem;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  outline: none;
  resize: none;
  &:focus {
    border-color: #007bff;
  }
`;

const Button = styled.button`
  background-color: #007bff;
  color: white;
  font-size: 1rem;
  font-weight: 600;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;

  &:hover {
    background-color: #0056b3;
  }
`;

const SuccessMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: 1.2rem;
  color: green;
  margin-top: 1rem;
`;

const TopImage = styled.img`
  width: 100%;
  max-width: 800px; /* Adjust as needed */
  border-radius: 2px;
  margin-bottom: 0.9rem;
  margin: 0 auto; /* Center the image */
`;

const SignupForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    cellphone: '',
    message: ''
  });

  const [submitted, setSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      console.log('process.env.REACT_APP_BACKEND_URL = ', process.env.REACT_APP_BACKEND_URL);
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/store-form`, formData);
      console.log('Form submitted successfully:', response.data);
      setSubmitted(true);
      setFormData({
        name: '',
        email: '',
        cellphone: '',
        message: ''
      });
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  const handleReset = () => {
    setSubmitted(false);
  };

  return (
    <Container>
      <Section>
        <TextContainer>
          <Title>Contact Us</Title>
          <Subtitle>Fill out the form below to get in touch with us.</Subtitle>
        </TextContainer>
        {submitted ? (
          <SuccessMessage>
            Thank you! Your message has been sent.
            <Button onClick={handleReset}>Send Another</Button>
          </SuccessMessage>
        ) : (
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <Label htmlFor="name">Name</Label>
              <Input type="text" id="name" name="name" value={formData.name} onChange={handleChange} required />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="email">Email</Label>
              <Input type="email" id="email" name="email" value={formData.email} onChange={handleChange} required />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="cellphone">Cellphone</Label>
              <Input type="tel" id="cellphone" name="cellphone" value={formData.cellphone} onChange={handleChange} required />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="message">Message</Label>
              <Textarea id="message" name="message" rows="4" value={formData.message} onChange={handleChange} required />
            </FormGroup>
            <Button type="submit">Send</Button>
          </Form>
        )}
      </Section>
    </Container>
  );
};

export default SignupForm;
