import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  padding: 1rem;
`;

const Section = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem; /* Increased gap to create space */
  padding: 1rem;
  width: 100%;
  max-width: 1200px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const Image = styled.img`
  width: 50%;
  border-radius: 8px;
`;

const TextContainer = styled.div`
  flex: 1;
`;

const Title = styled.h2`
  font-size: 2rem;
  margin-bottom: 1rem;
`;

const List = styled.ul`
  font-size: 1.2rem;
`;

const ListItem = styled.li`
  margin-bottom: 0.5rem;
`;

const IntegrationService = () => {
  return (
    <Container>
      <Section>
        <Image src="/images/integration3.png" alt="Integration Service" />
        <TextContainer>
          <Title>Proposed Integration Service</Title>
          <List>
            <ListItem>5G Connectivity.</ListItem>
            <ListItem>AI DataCenters and Edge Devices.</ListItem>
            <ListItem>MLOPS.</ListItem>
            <ListItem>Data Ops.</ListItem>
            <ListItem>Workload Refactoring and Migration.</ListItem>
            <ListItem>Centralized Monitoring, Security Assurance and Standardized DevSecOps.</ListItem>
          </List>
        </TextContainer>
      </Section>
    </Container>
  );
};

export default IntegrationService;
