import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  padding: 1rem;
`;

const Section = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem; /* Increased gap to create space */
  padding: 1rem;
  width: 100%;
  max-width: 1200px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const Image = styled.img`
  width: 50%;
  border-radius: 8px;
`;

const TextContainer = styled.div`
  flex: 1;
`;

const Title = styled.h2`
  font-size: 2rem;
  margin-bottom: 1rem;
`;

const List = styled.ul`
  font-size: 1.2rem;
`;

const ListItem = styled.li`
  margin-bottom: 0.5rem;
`;

const AiInfrastructure = () => {
  return (
    <Container>
      <Section>
        <Image src="/images/iabackground3.png" alt="AI Infrastructure" />
        <TextContainer>
          <Title>The AI Infrastructure</Title>
          <List>
            <ListItem>State-of-the-art AI-enabled built-in cost optimization tool and other infrastructure fine-tuned for lowest cost of ownership.</ListItem>
            <ListItem>Performance: Our infrastructure is fine-tuned for distributed computing, concurrencies and hosted on industry leading cloud and on-prem GPU/CPU infrastructure.</ListItem>
            <ListItem>Zero Trust Enablement: Our platform is zero trust enabled from development to deployment to monitoring.</ListItem>
            <ListItem>Training and Inference Service on Industrial Grade Infrastructure.</ListItem>
          </List>
        </TextContainer>
      </Section>
    </Container>
  );
};

export default AiInfrastructure;
