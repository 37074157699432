import React from 'react';
import styled from 'styled-components';
import ProductCard from './ProductCard';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  gap: 1rem;
  padding: 1rem;
`;

const Section = styled.div`
  flex: 1 1 30%;
  // border-radius: 10px;
  padding: 1rem;
  // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const SectionTitle = styled.h2`
  text-align: center;
  margin-bottom: 1rem;
  color: #333;
`;

const CardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  flex: 1;
`;

const Solutions = () => {
  const aiInfrastructure = [
    {
      title: 'State-of-the-art AI-enabled built-in cost optimization tool and other infrastructure fine-tuned for lowest cost of ownership',
      borderColor: '#00D4FF'
    },
    {
      title: 'Performance: Our infrastructure is fine-tuned for distributed computing, concurrencies and hosted on industry leading cloud and on-prem GPU/CPU infrastructure',
      borderColor: '#00D4FF'
    },
    {
      title: 'Zero Trust Enablement: Our platform is zero trust enabled from development to deployment to monitoring',
      borderColor: '#00D4FF'
    },
    {
      title: 'Training and Inference Service on Industrial Grade Infrastructure',
      borderColor: '#00D4FF'
    }
  ];

  const products = [
    {
      title: 'Network Anomaly Detection',
      borderColor: '#00D4FF'
    },
    {
      title: 'LLM Based Knowledge Base',
      borderColor: '#00D4FF'
    },
    {
      title: 'Situational Analysis and Summarization at the Edge (Video/Audio Feed)',
      borderColor: '#00D4FF'
    },
    {
      title: 'Conversation Customer Support with fine tuned Models',
      borderColor: '#00D4FF'
    }
  ];

  const integrationServices = [
    {
      title: '5G Connectivity',
      borderColor: '#00D4FF'
    },
    {
      title: 'AI DataCenters and Edge Devices',
      borderColor: '#00D4FF'
    },
    {
      title: 'MLOPS',
      borderColor: '#00D4FF'
    },
    {
      title: 'Data Ops',
      borderColor: '#00D4FF'
    },
    {
      title: 'Workload Refactoring and Migration',
      borderColor: '#00D4FF'
    },
    {
      title: 'Centralized Monitoring, Security Assurance and Standardized DevSecOps',
      borderColor: '#00D4FF'
    }
  ];

  return (
    <Container>
      <Section>
        <SectionTitle>The AI Infrastructure</SectionTitle>
        <CardsContainer>
          {aiInfrastructure.map((item, index) => (
            <ProductCard key={index} title={item.title} description={item.description} borderColor={item.borderColor} />
          ))}
        </CardsContainer>
      </Section>
      <Section>
        <SectionTitle>The Products</SectionTitle>
        <CardsContainer>
          {products.map((item, index) => (
            <ProductCard key={index} title={item.title} description={item.description} borderColor={item.borderColor} />
          ))}
        </CardsContainer>
      </Section>
      <Section>
        <SectionTitle>Proposed Integration Service</SectionTitle>
        <CardsContainer>
          {integrationServices.map((item, index) => (
            <ProductCard key={index} title={item.title} description={item.description} borderColor={item.borderColor} />
          ))}
        </CardsContainer>
      </Section>
    </Container>
  );
};

export default Solutions;
