import React from 'react';
import styled from 'styled-components';


const sponsors = [
  { src: '/images/dod.png', alt: 'Department of Defense' },
  { src: '/images/whitehouse.png', alt: 'White House' },
  { src: '/images/oracle.png', alt: 'Oracle' },
];


const SponsorsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  background-color: white; /* Ensure background is white */
`;

const Title = styled.div`
  color: #DEDEDE; /* Soft grey color */
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
  margin-right: 1rem;
  white-space: pre-line; /* Allows for breaking lines */
`;

const SponsorImage = styled.img`
  margin: 0 1rem;
  width: 100px; /* Adjust size as needed */
  height: auto;
`;

const Sponsors = () => {
  return (
    <SponsorsContainer>
      <Title>Partners &{'\n'}Sponsors</Title>
      {sponsors.map((sponsor, index) => (
        <SponsorImage key={index} src={sponsor.src} alt={sponsor.alt} />
      ))}
    </SponsorsContainer>
  );
};

export default Sponsors;
