import React from 'react';
import styled from 'styled-components';

const FooterContainer = styled.footer`
  background: white; /* Ensure background is white */
  padding: 1rem 2rem;
  text-align: center;
  margin-top: auto;
`;

const FooterText = styled.p`
  font-size: 1rem;
  color: #DEDEDE;
`;

const Footer = () => {
  return (
    <FooterContainer>
      {/* <FooterText>© 2024 Simply Data. All rights reserved.</FooterText> */}
    </FooterContainer>
  );
};

export default Footer;
