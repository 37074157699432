import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom'; // Import the Link component from react-router-dom

const Home = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
  background: white;
  flex: 1;
  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
  }
`;

const TextContainer = styled.div`
  flex: 0 0 30%; /* Occupies 30% of the width */
  color: black;
  padding: 4rem;
  box-sizing: border-box; /* Ensures padding is included in the width */
`;

const PreTitle = styled.p`
  font-size: 1.2rem; /* Smallest text size */
  color: black;
  font-weight: 400;
  font-family: 'Inter', sans-serif;
  margin-bottom: 0.5rem;
`;

const Title = styled.h1`
  font-size: 2.5rem; /* Adjusted for better fit */
  color: black;
  font-weight: 700; /* Bold weight */
  font-family: 'Inter', sans-serif;
  margin-bottom: 1rem;
`;

const Subtitle = styled.p`
  font-size: 1rem; /* Adjusted for better fit */
  font-weight: 200; /* Regular weight */
  font-family: 'Inter', sans-serif;
  margin-bottom: 2rem;
  // color: #ADADAD;
`;

const Button = styled(Link)` // Use Link component from react-router-dom
  background-color: #007BFF;
  color: white;
  font-size: 1rem;
  font-weight: 600;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: inline-flex; /* Change to inline-flex to keep size */
  align-items: center;
  justify-content: center; /* Center the text inside the button */
  text-decoration: none; // Ensure the link has no underline
  width: fit-content; /* Ensure button width is only as wide as its content */

  &:hover {
    background-color: #0056b3;
  }
`;

const ImageContainer = styled.div`
  flex: 0 0 70%; /* Occupies 70% of the width */
  padding: 1rem;
  box-sizing: border-box; /* Ensures padding is included in the width */
`;

const HomeImage = styled.img`
  width: 100%;
  height: auto;
  max-height: 60vh; /* Ensure the image does not take up too much space */
`;

const HomeSection = () => {
  return (
    <Home>
      <TextContainer>
        <PreTitle><b>Trident Inter</b> provides</PreTitle>
        <Title>Safety and Technology</Title>
        <Subtitle>For global infrastructures, enabling both defense and non-defense agencies to swiftly and ethically adopt new technologies for mission-critical workloads and future initiatives.</Subtitle>
        <Button to="/products">Our Products &rarr;</Button>
      </TextContainer>
      <ImageContainer>
        <HomeImage src={`${process.env.PUBLIC_URL}/images/bg1.png`} alt="Analytics Illustration" />
      </ImageContainer>
    </Home>
  );
};

export default HomeSection;
