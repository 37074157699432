import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  padding: 1rem;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2rem;
  padding: 1rem;
  width: 100%;
  max-width: 1200px;
  background-color: #fff;
  border-radius: 8px;
  // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const TextContainer = styled.div`
  flex: 1;
  margin-left: auto;
  margin-right: auto;
  max-width: 600px; /* Limit the width of the text container */
`;

const Title = styled.h2`
  font-size: 2rem;
  margin-bottom: 1rem;
`;

const Paragraph = styled.p`
  font-size: 1rem;
  margin-bottom: 1rem;
  line-height: 1.6;
`;

const TopImage = styled.img`
  width: 50%;
  max-width: 800px; /* Adjust as needed */
  border-radius: 8px;
  margin-bottom: 2rem;
  margin: 0 auto; /* Center the image */
`;

const AboutUs = () => {
  return (
    <Container>
      <Section>
      <TopImage src={`${process.env.PUBLIC_URL}/images/aboutus.png`} alt="Logo" />
        <TextContainer>
          <Title>About Us</Title>
          <Paragraph>
            We are a pre-seed Start up waiting for grant by the Department of Defense and the White House.
          </Paragraph>
          <Paragraph>
            We are building a zero trust enabled, cost-optimized Platform that can be deployed to government and critical infrastructure industry.
          </Paragraph>
          <Paragraph>
            Our solutions also include integration services for clients for their workload migration into a standardized hybrid mutlicloud infrstructure which is secured, performant and adheres to the high standard of compliance that is demanded at the DoD and by The White House.
          </Paragraph>
        </TextContainer>
      </Section>
    </Container>
  );
};

export default AboutUs;
