import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  padding: 1rem;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2rem;
  padding: 1rem;
  width: 100%;
  max-width: 1200px;
  background-color: #fff;
  border-radius: 8px;
//   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const TextContainer = styled.div`
  flex: 1;
  margin-left: auto;
  margin-right: auto;
  max-width: 800px; /* Limit the width of the text container */
`;

const Title = styled.h2`
  font-size: 2rem;
  margin-bottom: 1rem;
`;

const Paragraph = styled.p`
  font-size: 1rem;
  margin-bottom: 1rem;
  line-height: 1.6;
`;

const TopImage = styled.img`
  width: 50%;
  max-width: 800px; /* Adjust as needed */
  border-radius: 8px;
  margin-bottom: 2rem;
  margin: 0 auto; /* Center the image */
`;

const Mission = () => {
    return (
        <Container>
            <Section>
            <TopImage src={`${process.env.PUBLIC_URL}/images/mission.png`} alt="Logo" />
                <TextContainer>
                    <Title>Our Mission</Title>
                    <Paragraph>
                        The World is undergoing a paradigm shift both technologically and
                        geo-politically. These changes are bringing increased risks and at the
                        same increased opportunities. Government agencies both domestic and in
                        the Allied countries are struggling to keep pace with this rapid
                        transformation.

                    </Paragraph>
                    <Paragraph>
                        Bureacracy,lack of funding,shortage of talents and lack of
                        a centralized strategy are some of the key constraints that will hold
                        back the transition to this new landscape that the private sector is
                        rapidly evolving into.
                    </Paragraph>
                    <Paragraph>
                        There needs to be a central inititiave across the
                        agencies which can consolidate the technologies and at the same time
                        build the infrastructure that provides entities to adopt these
                        technologies in a safe,ethical and compliant manner and fast.
                    </Paragraph>
                    <Paragraph>
                        Langleyops
                        Mission is to provide a global infrastructure that both defense and
                        non-defense agencies can leverage to build mission critical workloads and
                        plan their furture initiatives.
                    </Paragraph>
                </TextContainer>
            </Section>
        </Container>
    );
};

export default Mission;





