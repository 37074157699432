import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import styled from 'styled-components';
import Navbar from './components/Navbar';
import HomeSection from './components/HomeSection';
import Sponsors from './components/Sponsors';
import Footer from './components/Footer';
import SignupForm from './components/SignupForm';
import GlobalStyle from './globalStyles';
import Solutions from './components/Solutions';
import AiInfrastructure from './components/AiInfrastructure';
import Products from './components/Products';
import IntegrationService from './components/IntegrationService';
import Mission from './components/mission';
import AboutUs from './components/AboutUs';

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh; /* Full viewport height */
`;

const FixedNavbar = styled.div`
  flex-shrink: 0; /* Do not shrink */
`;

const Content = styled.div`
  flex: 1; /* Take up remaining space */
  display: flex;
  flex-direction: column;
  overflow-y: auto; /* Scroll if necessary */
`;

const FixedSponsors = styled.div`
  flex-shrink: 0; /* Do not shrink */
`;

function App() {
  return (
    <Router>
      <GlobalStyle />
      <AppContainer>
        <FixedNavbar>
          <Navbar />
        </FixedNavbar>
        <Content>
          <Routes>
            <Route path="/" element={<HomeSection />} />
            <Route path="/mission" element={<Mission />} />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/contact" element={<SignupForm />} />
            <Route path="/ai-infrastructure" element={<AiInfrastructure />} />
            <Route path="/products" element={<Products />} />
            <Route path="/integration-service" element={<IntegrationService />} />
          </Routes>
        </Content>
        <FixedSponsors>
          <Sponsors />
          <Footer />
        </FixedSponsors>
      </AppContainer>
    </Router>
  );
}

export default App;
