import React from 'react';
import styled from 'styled-components';

const Card = styled.div`
  background: #fff;
  color: #333;
  border-radius: 8px;
  padding: 1rem;
  margin: 0 auto;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: calc(100% - 2rem);
  border: 4px solid ${props => props.borderColor || '#ccc'};
`;

const Title = styled.h2`
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 1rem;
`;

const Description = styled.p`
  font-size: 0.9rem;
`;

const ProductCard = ({ title, description, borderColor }) => {
  return (
    <Card borderColor={borderColor}>
      <Title>{title}</Title>
      <Description>{description}</Description>
    </Card>
  );
};

export default ProductCard;
