import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: white;
  margin: 1rem;
`;

const LogoLink = styled(Link)`
  display: flex;
  align-items: center;
`;

const LogoImage = styled.img`
  height: 50px;
  width: auto;
`;

const NavLinks = styled.div`
  display: flex;
  gap: 1rem;
`;

const NavLink = styled(Link)`
  text-decoration: none;
  color: black;
  font-size: 1rem;
  font-family: 'Inter', sans-serif;
  &:hover {
    color: #007BFF;
  }
`;

const Dropdown = styled.div`
  position: relative;
  display: inline-block;
`;

const DropdownContent = styled.div`
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  ${Dropdown}:hover & {
    display: block;
  }
`;

const DropdownLink = styled(Link)`
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  &:hover {
    background-color: #f1f1f1;
  }
`;

const Navbar = () => {
  return (
    <Nav>
      <LogoLink to="/">
        <LogoImage src={`${process.env.PUBLIC_URL}/images/logo.png`} alt="Trident International Logo" />
      </LogoLink>
      <NavLinks>
        <NavLink to="/mission">Mission</NavLink>
        <Dropdown>
          <NavLink to="/solutions">Solutions</NavLink>
          <DropdownContent>
            <DropdownLink to="/ai-infrastructure">AI Infrastructure</DropdownLink>
            <DropdownLink to="/products">Products</DropdownLink>
            <DropdownLink to="/integration-service">Integration Service</DropdownLink>
          </DropdownContent>
        </Dropdown>
        <NavLink to="/about">About</NavLink>
        <NavLink to="/contact">Contact</NavLink>
      </NavLinks>
    </Nav>
  );
};

export default Navbar;
